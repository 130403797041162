import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, Title, TextBlock, Button,
} from '@lxlabs/change-ad-components';
import styled from 'styled-components';
import Graphic from './graphic';

const Style = styled(Card)`
  padding-bottom: 47px;
`;

const Description = styled(TextBlock)`
  max-width: 323px;
`;

const HeaderImage = styled.div`
  margin-top: calc(var(--spacing-xlarge) * -1);
  margin-bottom: 37px;
  width: calc(100% + var(--spacing-xlarge) * 2);
  padding-bottom: 200px;
  border-radius: 15px 15px 0px 0px;

  background-image: ${({ image }) => `url(${image})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  @media screen and (max-width: 834px) {
    margin-top: calc(var(--spacing-medium) * -1);;
    width: calc(100% + var(--spacing-small) * 2);
  }
`;

const resourceCard = ({
  image, imageAlt, title, description, link, buttonText, headerImage,
  backgroundColor, number, icon, download,
}) => (
  <Style backgroundColor={backgroundColor} cyData={`card-${title}`} halfWidth>
    <Title size="large">{number}</Title>
    {image && <Graphic name={image} alt={imageAlt} />}
    { headerImage && <HeaderImage image={require(`../../assets/image/${headerImage}`).default} />}
    <Title size="small" alignment="center">{title}</Title>
    <Description size="small" alignment="center" spacing={{ top: 'medium', bottom: 'medium' }}>
      {description}
    </Description>
    <Button text={buttonText} href={link} icon={icon} download={download} />
  </Style>
);

resourceCard.propTypes = {
  /**
   * The graphic name to include
   */
  image: PropTypes.string,
  /**
   * Alt text for the image - used for accessibility.
   */
  imageAlt: PropTypes.string,
  /**
   * Title to appear on the card
   */
  title: PropTypes.string.isRequired,
  /**
   * Description to appear on the card
   */
  description: PropTypes.string.isRequired,
  /**
   * Path of the link
   */
  link: PropTypes.string.isRequired,
  /**
   * Text to appear on the button
   */
  buttonText: PropTypes.string.isRequired,
  /**
   * Path to the header image to appear at the top of the card.
   */
  headerImage: PropTypes.string,
  /**
   * Background color of the card
   */
  backgroundColor: PropTypes.string,
  /**
   * number of the card
   */
  number: PropTypes.string,
};

resourceCard.defaultProps = {
  image: '',
  imageAlt: '',
  headerImage: '',
  backgroundColor: 'secondary-30',
  number: '',
};

export default resourceCard;
